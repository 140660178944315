





































































































































































































































































































































@import "../assets/vars";
.vacancies_wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.vacancies_top_wrapper {
}

.vacancies_items_wrapper {
  flex: 1;
  position: relative;
}

.vacancies_items_wrapper_rec {
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
}

.vacancies_items_bottom {
  border-top: 1px solid @color_lines_gray;
  padding: 3px 50px;
  font-size: 13px;
  color: @color_gray_text;
}

.scroller {
  height: 100%;
  overflow-y: scroll !important;
}

.vacancies_item {
  height: 70px;
  padding: 0 50px;
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 1px solid @color_lines_gray;
  overflow: hidden;
}

.vacancies_item:hover {
  background: @color_bg;
}

.vacancies_top_toolbar {
  padding: 10px 50px;
  background: white;
  border-bottom: 3px solid @color_lines_gray;
}

.vacancies_top_th {
  background: @color_bg;
  border-bottom: 1px solid @color_lines_gray;
  padding: 7px 50px;
  display: flex;
}

.vacancies_item_width {
  padding-right: 20px;
}

.vacancies_item {
  .vacancies_item_width {
    padding-left: 14px;
  }

  .vacancies_item_width_checkbox {
    padding-left: 0;
  }
}

.vacancies_item_width_checkbox {
  width: 4%;
  display: flex;
  align-items: center;
}

.vacancies_item_width_title {
  width: 20%;
}

.vacancies_item_width_city {
  width: 28%;
}
.vacancies_item_width_vac_id {
  width: 20%;
}

.vacancies_item_width_reply {
  width: 28%;
  padding-right: 0;
}

.vacancies_empty_box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.vacancies_item_ext_link {
  font-size: 14px;
  color: #c9c9c9;
}

.vacancies_item_ext_link:hover {
  color: #000000;
}

.page_loader {
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 100;
}
