

















@import "../assets/vars";

.login_box {
  background: white;
  border: 1px solid @color_lines_gray;
  border-radius: 20px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  min-width: 400px;
  justify-content: center;
  align-items: center;
}

.login_box_inner {
  width: 80%;
}
