
































@import "../assets/vars";
.page_header {
  width: 100%;
  border-bottom: 1px solid @color_lines_gray;

  background: white;
}

.page_header_inner {
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.page_header_title {
  font-size: 24px;
  font-weight: 600;
}

.page_header_back {
  font-size: 14px;

  a {
    text-decoration: none;
  }
}
