








































@import "../../assets/vars";

.base_button {
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  background: @color_base;
  padding: 0 14px;
  color: white;
  border-radius: 50px;
  text-decoration: none !important;
}

.base_button:hover {
  background: @color_black;
}

.button-inactive {
  background: #dcdcdc;
}

.button-inactive:hover {
  background: #dcdcdc;
}

.button-type-red {
  background: @color_red;
}
.button-type-black {
  background: #000000;
}
.button-type-black:hover {
  background: @color_base;
}

.button-type-gray {
  background: @color_gray_text;
}

.button-type-white {
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);

  background-color: #f7fafc;
  border-color: #f7fafc;
  font-weight: 600;
  color: @color_base;
}
.button-type-white:hover {
  color: @color_dark;
  background-color: #f7fafc;
}

.button-type-white_flat {
  box-shadow: 0 2px 2px rgb(43 53 66 / 21%), 0px 1px 0px rgb(0 0 0 / 26%);

  background-color: white;
  border-color: #f7fafc;
  font-weight: 600;
  color: @color_base;
}
.button-type-white_flat:hover {
  color: @color_dark;
  background-color: white;
}

.button-type-rounded_gray {
  border: 1px solid @color_buttons_round;
  background-color: white;
  font-weight: 600;
  color: @color_gray_text;
}

.button-type-rounded_gray:hover {
  color: @color_base;
  background-color: white;
}

.button-type-rounded {
  border: 1px solid @color_buttons_round;
  background-color: white;
  font-weight: 600;
  color: @color_base;
}

.button-type-rounded:hover {
  color: @color_dark;
  background-color: white;
}

.button-size-big {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 20px;
}
