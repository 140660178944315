

































.popup_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 100;
}

.fade {
  background: rgba(0, 0, 0, 0.56);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popup_box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 600px;
  padding: 30px;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 20px;
  z-index: 200;
}

.close_button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
}

.close_button:hover {
  svg {
    fill: #000000;
  }
}
