















.card {
  background: white;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 25px;
}
