














































































































































@import "../../assets/vars";
.chat_list_wrapper {
  height: 100%;
  min-width: 260px;
  width: 260px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.chat_list_chats {
  overflow-y: auto;
  flex: 1;
  //transition: 0.2s;
  position: relative;
}

.avatar {
  img {
    width: 45px;
    border-radius: 100px;
  }
}

.chat_list_top {
  padding: 12px 6px !important;
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  display: flex;
  align-items: center;
}

.chat_item {
  cursor: pointer;
  padding: 6px 6px !important;
  padding-right: 0 !important;

  h4 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.chat_list_closed_item {
  color: #999999;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 16px;
  padding-top: 50px;
}

.chat_item_row {
  display: flex;
  border-radius: 10px;
}

.chat_item_active {
  opacity: 1;

  color: #fff;

  .chat_item_row {
    background: #2ec0c3;
  }

  .avatar {
    background: #fff;
    color: #2ec0c3;

  }

  .chat_list_active_green {
    opacity: 0;
  }

  h4 {
    color: #fff;
  }
}

.chat_item_show {
  display: block;
}

.list {
  padding: 0;
  list-style: none;
}

.chat_list_img {
  display: flex;
  padding: 4px 4px;
  padding-right: 10px;
}

.chat_item_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  overflow: hidden;
  color: @color_gray_links;
  border-bottom: 1px solid @color_lines_gray;

}



.chat_list_right {
  align-self: flex-start;
  padding-top: 8px;
  font-size: 12px;
}

.chat_list_text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  h4 {
    color: @color_black;
    font-weight: 600;
  }
}

.list-move {
  transition: transform 0.5s;
}

.chat_list_chats::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.chat_list_chats::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.chat_list_chats::-webkit-scrollbar-thumb {
  background-color: #adb5bd;
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid transparent;
  background-clip: content-box;
}

.chat_list_active_green {
  width: 7px;
  height: 7px;
  border-radius: 100px;
  background: #00dea2;
  align-self: center;
  position: absolute;
}

.chat_list_label {
  input {
    margin-right: 7px;
  }

  cursor: pointer;
  margin-bottom: 0;
  font-size: 13px;
}

.chat_list_no_work {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
  color: #999999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  i {
    font-size: 50px;
  }
}
