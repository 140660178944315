






























.page_content {
  flex: 1;
  overflow-y: auto;
  padding: 50px;
  position: relative;
}

.page_content_inner {
}

.page_loader {
  transition: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}
