

















@import "../assets/vars";
.app {
  width: 100%;
  height: 100vh;
  background: @color_bg;
  display: flex;
}

.main_wrapper {
  flex: 1;
}

body {

}

