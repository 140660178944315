






















































































































































































@import "../../assets/vars";
.notifications .alert {
  position: fixed;
  z-index: 100000000000;

  &[data-notify="container"] {
    max-width: 500px;

    @media @small {
      width: auto;
      left: 20px;
      margin-left: 0 !important;
    }
  }

  &.center {
    margin: 0 auto;
  }
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
    margin-left: 20px;
  }

  .close {
    font-size: 20px;
  }
}

.notifications {
  .alert {
    color: white;
    background: @color_green;
    padding: 20px;
    padding-right: 50px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    z-index: 100000000000;

    .close {
      color: white;
    }
  }

  .alert-success {
    color: white;
    background: @color_green;
    .close {
      color: white;
    }
  }

  .alert-warning {
    color: white;
    background: @color_red;
    .close {
      color: white;
    }
  }

  .close {
    background: transparent;
    margin-left: 20px;
    position: absolute;
    right: 0;
    height: 100%;
    width: 50px;
    cursor: pointer;
  }
}
