












































.dashboard_wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 18px;

  .card {
    padding: 50px;
  }
}

h1 {
  margin-bottom: 40px;
}
