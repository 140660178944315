













































@import "../assets/vars";
.chat_big_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
}
.chat_big_wrapper_window {
  flex: 1;
}
.chat_big_wrapper_list {
  background: #fff;
  //box-shadow: 7px 0 1rem 0 rgba(136, 152, 170, 0.26) !important;
  border-right: 1px solid @color_lines_gray;
  z-index: 1;
}
.chat_big_wrapper_list_in_chat {
  flex: 1;
}

.chat_right {
  display: flex;
  flex: 1;
}

.chat_clear_window {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
