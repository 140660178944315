







































































































.bind_account_wrapper {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
