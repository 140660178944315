

















































































































































































































































































































.client_card {
  height: 100%;
  //box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%) !important;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  z-index: 1;
  border-left: 1px solid;
  border-color: #f1f1f1;
  width: 250px;
  padding: 5px;
  overflow-y: auto;
}

.client_card_info_box {
  flex: 1;
  overflow-y: auto;
  margin-top: 20px;
  word-break: break-word;
}

.client_card_info_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.client_card_info_text {
  white-space: break-spaces;
  margin-bottom: 15px;
}

.client_card_info {
  width: 450px;
}

.client_img {
  img {
    width: 100%;
  }
  margin-bottom: 10px;
}

.client_name {
  font-weight: 600;
  font-size: 17px;
}

.client_platform {
  font-weight: 600;
  font-size: 14px;
}

.client_sub {
  margin-top: 20px;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 600;
}

.client_info {
  margin-bottom: 20px;
}

.close_client_chat {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
  background: transparent;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}

.client_card_scheduled_box_text {
  font-size: 14px;
}

.client_card_padding {
  padding: 15px;
}

.client_card_box {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  padding: 15px;
}

.client_card_scheduled_box {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.client_card_main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.client_card_main_bottom {
  padding: 13px;
  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.client_card_main_bottom_circle {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: #f5365c;
  margin-right: 13px;
}

.client_card_main_bottom_circle_green {
  background: #00dea2;
}

.client_card_scheduled_box_schedule {
  background: #fffece;
}
