













@import "../../assets/vars";
.badge {
  font-size: 12px;
  padding: 3px 6px;
  background: #ebebeb;
  color: @color_gray_links;
  display: inline;
  border-radius: 7px;
  font-weight: 600;
}

.badge-type-red {
  color: @color_red;
}

.badge-type-black {
  color: #000000;
}
