.avatar_box {
  position: relative;
}
.avatar_source {
  position: absolute;
  right: -5px;
  bottom: 1px;
  border-radius: 100px;
  border: 5px solid #fff;
  background: #fff;
  width: 23px;
  height: 23px;
}
.avatar_source img {
  width: 15px !important;
  height: auto !important;
}
button svg,
button img {
  display: inherit;
}
