































@import "../assets/vars";
.sidebar {
  width: 100%;
  max-width: 200px;
  border-right: 5px solid @color_lines_gray;
  background: white;
}

.sidebar_wrapper {
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  a {
    font-size: 16px;
    font-weight: 600;
    color: @color_gray_links;
    text-decoration: none;
    margin-bottom: 11px;
    transition: 0.2s;
    margin-left: -18px;
  }

  a:hover {
    color: @color_base;
  }

  a:before {
    content: "❯";
    margin-right: 10px;
    opacity: 0;
    transition: 0.2s;
  }

  .router-link-active {
    margin-left: -12px;
  }

  .router-link-active,
  .router-link-active:hover {
    color: @color_dark;
    cursor: auto;
  }

  .router-link-active:before {
    opacity: 1;
  }
}
