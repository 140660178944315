





























































.auto_reply_title {
  font-size: 18px;
  font-weight: 600;
}
