

















































































































































































































@import "../../assets/vars";

.chat_window {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat_header {
  background: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid @color_lines_gray;
}

.chat_header_left {
  display: flex;
  gap: 15px;
  align-items: center;


  img {
    height: 45px;
    border-radius: 100px;
  }
}
.chat_header_left_name {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0px;
}

.chat_header_left_title {
  font-size: 13px;
}

.chat_input {
  flex: 1;
  padding: 7px 10px;
  border-radius: 0.375rem;
  outline: none;
  position: relative;
  height: 100%;
}

.chat_input_later_notify {
  min-height: 50px;
  background: rgba(11, 144, 168, 0.18);
}

.chat_messages_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: @color_bg;
}

.later_button {
  margin-right: 15px;
  color: #7a7a7a;
}

.later_suggest_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  button {
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    font-size: 14px;
    color: #575757;
  }

  button:last-child {
    border-bottom: none;
  }
}

.suggested_phrases_popup {
  position: absolute;
  bottom: 60px;
  left: 0;
  opacity: 0.7;
  width: 100%;

  .later_box {
    width: 100%;
  }

  .later_suggest_box {
    width: 100%;

    button {
      text-align: left;
      padding: 10px 10px;
      font-size: 14px;
      color: #575757;
      background: #fff;
      margin-bottom: 5px;
      border-radius: 9px;
    }

    .is-active {
      background: #2ec0c3;
      color: #fff;
      box-shadow: 0px 2px 9px #00000029;
    }
  }
}

.suggested_phrases_popup_active {
  opacity: 1;
}

.chat_input_box {
  border-top: 1px solid @color_lines_gray;
  background: #fff;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    border: none;
    outline: none;
    width: 100%;
    padding: 0 20px;
    height: 45px;
  }
}

.chat_input_box_right {
  display: flex;
  align-items: center;

  button {
    padding: 7px 20px;

  }
}

.chat_msg {
  background: #fff;
  flex: 0;
  width: max-content;
  border-radius: 6px 12px 12px 6px;
  margin-top: 10px;
  display: flex;
  overflow: hidden;
  position: relative;
}

.chat_msg_text {
  padding: 6px 0.5rem 0.375rem 0.625rem;
  max-width: 450px;
}

.chat_msg_images {
  max-width: 450px;

  img,
  video {
    max-width: 100%;
  }
}

.chat_msg_my {
  align-self: flex-end;
  border-radius: 12px 6px 6px 12px;
  width: 100%;
  display: grid;

  .chat_msg {
    background: #d3f1af;
    justify-self: end;
  }
}

.chat_msg_date_div {
  margin: 10px 0;
  margin-top: 20px;
  border-radius: 25px;
  background: transparent;
  color: @color_gray_links;
  width: max-content;
  align-self: center;
  justify-self: center;
  font-weight: 600;
  font-size: 13px;
}

.chat_msg_date {
  font-size: 12px;
  color: #999999;
  align-self: end;
  padding: 3px 4px 1px 0;
}

.chat_msg_date_read {
  color: #2dce89;
}

.chat_msg_wrapper {
  white-space: pre-line;
  display: grid;
}

.chat_msg_date_attaches {
  position: absolute;
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  right: 4px;
  bottom: 3px;
  border-radius: 13px;
  padding: 4px 8px 2px;
}

.chat_messages_box::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

.chat_messages_box::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

.chat_messages_box::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid transparent;
  background-clip: content-box;
}

.later_box {
  width: 700px;
}

.chat_msg_notify {
  background: #fffece !important;
}
