













@import "../assets/vars";
.errors_box_wrapper {
  color: @color_red;
  margin-bottom: 20px;
}
