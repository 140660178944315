


















.select_indicator {
  svg {
    fill: rgba(0, 0, 0, 0.82);
  }
}
